
import { mixins } from "vue-class-component";
import ModalMixin from "@/mixins/ModalMixin";
import { Options } from "vue-property-decorator";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import { namespace } from "s-vuex-class";
import useAuth from "@/composable/useAuth";

const ProfileModule = namespace("profile");

@Options({})
export default class DeleteAccountModal extends mixins(ModalMixin) {
  public name = "DeleteAccountModal";

  public auth = useAuth();

  @ProfileModule.Action("deleteAccount")
  public deleteAccount!: (options: ICrudOptions) => Promise<void>;

  @ProfileModule.Getter("getSuccess")
  public deleteAccountSuccess!: boolean;

  public onDeleteStudent() {
    this.showModal(null, "deletingAccount");
  }

  private async okDelete() {
    await this.deleteAccount({ descriptionField: "", resource: "students/remove" });
    if (this.deleteAccountSuccess) {
      this.closeDelete();
      setTimeout(() => {
        this.auth.signOut();
      }, 300);
    }
  }

  private closeDelete() {
    this.hideModal(null, "deletingAccount");
  }
}
